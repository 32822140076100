

<div class="container">
    <div class="row justify-content-center " *ngIf="(company$ | async)">
        <div class="col" *ngIf="(company$ | async)?.logo">
            <img [src]="(company$ | async)?.logo" alt="logo" class="img-fluid" style="height: 50px">
        </div>

        <div class="col my-auto">
            <h3>{{ (company$ | async)?.name ?? '' }}</h3>
        </div>
    </div>
</div>
