import {createAction, props} from "@ngrx/store";
import {
    LocalityType,
    LocationDeviceType,
    NeighborhoodType,
    TypeAlertType,
    TypeDeviceType, TypeSensorType
} from "../../shared/types/config.model";
import {AdminDeviceType} from "../../shared/types/company.model";


export const AppSelectCompany = createAction(
    '[App] Select Company',
    props<{ company_id: string| null }>()
);


export const AppAddListCompanies = createAction(
    '[App] Add List Companies',
);

export const AppAddListCompaniesSuccess = createAction(
    '[App] Add List Companies Success',
    props<{ companies: any[] }>()
);

export const AppAddListCompaniesFailure = createAction(
    '[App] Add List Companies Failure',
    props<{ error: any }>()
);


export const AppAddListNeighborhoods = createAction(
    '[App] Add List Neighborhoods',
);

export const AppAddListNeighborhoodsSuccess = createAction(
    '[App] Add List Neighborhoods Success',
    props<{ neighborhoods: NeighborhoodType[] }>()
);

export const AppAddListNeighborhoodsFailure = createAction(
    '[App] Add List Neighborhoods Failure',
    props<{ error: any }>()
);


export const AppAddListLocalities = createAction(
    '[App] Add List Localities',
);

export const AppAddListLocalitiesSuccess = createAction(
    '[App] Add List Localities Success',
    props<{ localities: LocalityType[] }>()
);

export const AppAddListLocalitiesFailure = createAction(
    '[App] Add List Localities Failure',
    props<{ error: any }>()
);




// types alerts

export const AppAddListTypeAlerts = createAction(
    '[App] Add List TypeAlerts',
);

export const AppAddListTypeAlertsSuccess = createAction(
    '[App] Add List TypeAlerts Success',
    props<{ type_alerts : TypeAlertType[] }>()
);

export const AppAddListTypeAlertsFailure = createAction(
    '[App] Add List TypeAlerts Failure',
    props<{ error: any }>()
);




// types devices

export const AppAddListTypeDevices = createAction(
    '[App] Add List TypeDevices',
);

export const AppAddListTypeDevicesSuccess = createAction(
    '[App] Add List TypeDevices Success',
    props<{ type_devices : TypeDeviceType[] }>()
);

export const AppAddListTypeDevicesFailure = createAction(
    '[App] Add List TypeDevices Failure',
    props<{ error: any }>()
);

// location devices

export const AppAddListTypeLocationsDevices = createAction(
    '[App] Add List TypeLocationsDevices',
);

export const AppAddListTypeLocationsDevicesSuccess = createAction(
    '[App] Add List TypeLocationsDevices Success',
    props<{ type_locations_device : LocationDeviceType[] }>()
);

export const AppAddListTypeLocationsDevicesFailure = createAction(
    '[App] Add List TypeLocationsDevices Failure',
    props<{ error: any }>()
);


// types sensors

export const AppAddListTypeSensors = createAction(
    '[App] Add List TypeSensors',
);

export const AppAddListTypeSensorsSuccess = createAction(
    '[App] Add List TypeSensors Success',
    props<{ type_sensors : TypeSensorType[] }>()
);

export const AppAddListTypeSensorsFailure = createAction(
    '[App] Add List TypeSensors Failure',
    props<{ error: any }>()
);

// devices company

export const AppAddListDevicesAdmin = createAction(
    '[App] Add List Devices Admin',
);

export const AppAddListDevicesAdminSuccess = createAction(
    '[App] Add List Devices Admin Success',
    props<{ devices : AdminDeviceType[] }>()
);

export const AppAddListDevicesAdminFailure = createAction(
    '[App] Add List Devices Admin Failure',
    props<{ error: any }>()
);