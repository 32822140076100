import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {from, of} from "rxjs";

import {ToastrService} from "ngx-toastr";
import {
    Firestore,
} from "@angular/fire/firestore";
import {AlertService} from "../../shared/services/alert.service";
import {
    DashboardAddListAlerts,
    DashboardAddListAlertsFailure,
    DashboardAddListAlertsSuccess, DashboardsDevicesUser, DashboardsUserDevicesFailure, DashboardsUserDevicesSuccess
} from "../actions/dashboard.actions";
import {UsersService} from "../../shared/services/users.service";


@Injectable()
export class DashboardEffects {
    constructor(private actions$: Actions,
                private _toastService: ToastrService,
                private readonly firestore: Firestore,
                private readonly alertService: AlertService,
                private readonly userService: UsersService

    ) {

    }

    DashboardAddListAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardAddListAlerts),
            switchMap(action =>
                from(this.alertService.getAlertsDashboard(action.user, action.start, action.end)).pipe(
                    map(data => DashboardAddListAlertsSuccess({alerts: data})),
                    catchError(err => of(DashboardAddListAlertsFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    DashboardsDevicesUser = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardsDevicesUser),
            switchMap(action =>
                from(this.userService.getDashboards(action.userId)).pipe(
                    map(data => DashboardsUserDevicesSuccess({dashboards: data})),
                    catchError(err => of(DashboardsUserDevicesFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

}
