import {serverTimestamp, Timestamp} from "@angular/fire/firestore";


class UsersModel {
    public created_at?: Date;
    public sede_id?: string;

  constructor(
    public id: string | null,
    public name: string,

    public email: string,

    public role: string,

    public active: boolean,

    public verified: boolean,

    public company: string,

    public dpto: string,

    public city: string,

    public document_type: string,

    public document: string,

    public urbanizacion: string | null,

    public locality: string,

    public phone: number,


    public front_security_id: string,

    created_at?: Timestamp | null,
    sede_id?: string | null,


  ) {

    this.created_at = created_at?.toDate();
    this.sede_id = sede_id;
  }


    toObject() {
        return {
            name: this.name,
            email: this.email,
            role: this.role,
            active: this.active,
            verified: this.verified,
            company: this.company,
            dpto: this.dpto,
            city: this.city,
            document_type: this.document_type,
            document: this.document,
            urbanizacion: this.urbanizacion,
            locality: this.locality,
            phone: this.phone,
            front_security_id: this.front_security_id,
        };
    }

}


export type  UserType = {
    id?: string;
    name: string;
    email: string;
    role: string;
    active: boolean;
    verified: boolean;
    company?: string;
    dpto?: string;
    city?: string;
    document_type?: string;
    document?: string;
    urbanizacion?: string;
    locality?: string;
    phone?: number;
    front_security_id?: string;
    created_at?: Date | Timestamp;
    sede_id?: string;
    neighborhood?: string;
    state_id?: number;
    password?: string;
    mac?: string;
}


export enum typeDocument {
    PASAPORTE = 'PASAPORTE',
    CE = 'CE',
    CC = 'CC',
    NIT = 'NIT',
}

export enum typeRole {
    user = 'user',
    admin = 'admin',
    admin_sede = 'admin_sede',
    super_admin = 'super_admin',
    operator = 'operator',
}


export type UserDashboardType = {
    id?: string;
    name: string;
    user_id: string;
    devices: Array<DashboardDeviceType>;
}

export type DashboardDeviceType = {
    id: string;
    col: number;
    maxSizeX?: number;
    maxSizeY?: number;
    minSizeX?: number;
    minSizeY?: number;
    row: number;
    sizeX?: number;
    sizeY?: number;


}