import {createReducer, on} from '@ngrx/store';
import {AlertType} from "../../../@core/types/utils";
import {
    DashboardAddListAlerts,
    DashboardAddListAlertsFailure,
    DashboardAddListAlertsSuccess, DashboardsDevicesUser, DashboardsUserDevicesFailure, DashboardsUserDevicesSuccess
} from "../actions/dashboard.actions";
import {DashboardDeviceType, UserDashboardType} from "../../shared/types/users.models";



export const authFeatureKey = 'auth';

export interface State {
    error: string;
    alerts: AlertType[];
    start_date: Date;
    end_date: Date;
    dashboards: UserDashboardType[],
}

export const initialState: State = {
    error: '',
    alerts: [],
    start_date: new Date(),
    end_date: new Date(),
    dashboards: [],

};

export const reducer = createReducer(
    initialState,
    on(DashboardAddListAlerts, (state, a) => {
        return {
            ...state,
            end_date: a.end,
            start_date: a.start,
            alerts: [],
            error: ''
        };

    }),
    on(DashboardAddListAlertsSuccess, (state, {alerts} ) => {
        return{
            ...state,
            error: '',
            alerts: alerts
        }
    }),

    on(DashboardAddListAlertsFailure, (state, {error} ) => {
        return{
            ...state,
            error: error,
        }
    }),
    on(DashboardsDevicesUser, (state) => {
        return {
            ...state,
            dashboards: []
        }
    }),
    on(DashboardsUserDevicesSuccess, (state, {dashboards}) => {
        return {
            ...state,
            dashboards
        }
    }),

    on(DashboardsUserDevicesFailure, (state, {error}) => {
        return {
            ...state,
            error: error
        }
    })

);

