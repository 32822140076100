import {createReducer, on} from '@ngrx/store';

import {ActionButtonType, CompanyType, DeviceType, FrontSecurityType, HeadquartersType} from "../../shared/types/company.model";
import {
    CompanyAddListActionButton,
    CompanyAddListActionButtonFailure,
    CompanyAddListActionButtonSuccess,
    CompanyAddListCompanies,
    CompanyAddListCompaniesSuccess,
    CompanyAddListDevices, CompanyAddListDevicesSuccess,
    CompanyAddListFrontSecurity,
    CompanyAddListFrontSecuritySuccess,
    CompanyAddListHeadquarters,
    CompanyAddListHeadquartersSuccess, CompanyAddListUsers, CompanyAddListUsersSuccess
} from "../actions/company.actions";
import {UserType} from "../../shared/types/users.models";



export const companyFeatureKey = 'company';

export interface State {
    error: string;
    companies: CompanyType[];
    headquarters: HeadquartersType[];
    front_securities: FrontSecurityType[];
    devices: DeviceType[];
    users: UserType[];
    action_buttons: ActionButtonType[]

}

export const initialState: State = {
    error: '',
    companies: [],
    headquarters: [],
    front_securities: [],
    devices: [],
    users: [],
    action_buttons: []
};

export const reducer = createReducer(
    initialState,
    on(CompanyAddListCompanies, (state) => {
        return {
            ...state,
            companies: [],
            error: ''
        };

    }),
    on(CompanyAddListCompaniesSuccess, (state, {companies} ) => {
        return{
            ...state,
            error: '',
            companies: companies
        }
    }),

    on(CompanyAddListHeadquarters, (state) => {
        return {
            ...state,
            headquarters: [],
            error: ''
        };

    }),
    on(CompanyAddListHeadquartersSuccess, (state, {headquarters} ) => {
        return{
            ...state,
            error: '',
            headquarters: headquarters
        }
    }),

    on(CompanyAddListFrontSecurity, (state) => {
        return {
            ...state,
            front_securities: [],
            error: ''
        };

    }),
    on(CompanyAddListFrontSecuritySuccess, (state, {frontSecurities} ) => {
        return{
            ...state,
            error: '',
            front_securities: frontSecurities
        }
    }),


    on(CompanyAddListDevices, (state) => {
        return {
            ...state,
            devices: [],
            error: ''
        };

    }),
    on(CompanyAddListDevicesSuccess, (state, {all_devices} ) => {
        return{
            ...state,
            error: '',
            devices: all_devices
        }
    }),

    on(CompanyAddListUsers, (state) => {
        return {
            ...state,
            users: [],
            error: ''
        };

    }),
    on(CompanyAddListUsersSuccess, (state, {users} ) => {
        return{
            ...state,
            error: '',
            users: users
        }
    }),


    on(CompanyAddListActionButton, (state) => {
        return {
            ...state,
            error: '',
            action_buttons: []
        };
    }),
    on(CompanyAddListActionButtonSuccess, (state, {actions_buttons} ) => {
        return{
            ...state,
            error: '',
            action_buttons: actions_buttons
        }
    }),
    on(CompanyAddListActionButtonFailure, (state, {error} ) => {
        return{
            ...state,
            error: error,
            action_buttons: []
        }
    }),


);

