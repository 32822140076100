import {createReducer, on} from '@ngrx/store';
import {
    loginAuths,
    loginAuthsFailure,
    loginAuthsSuccess,
    logoutAuth,
    logoutAuthSuccess,
    verifyAuth, verifyAuthFailure, verifyAuthSuccess
} from "../actions/auth.actions";
import {DashboardDeviceType, UserType} from "../../shared/types/users.models";


export const authFeatureKey = 'auth';

export interface State {
    isLoggedIn: boolean;
    user?:UserType;
    error: string;

}

export const initialState: State = {
    isLoggedIn: false,
    user: null,
    error: '',
};

export const reducer = createReducer(
    initialState,
    on(loginAuths, (state) => {
    return {
      ...state,
        error: ''
    };
  }),

    on(verifyAuth, (state ) => {
        return {
            ...state,
        }
    }),
    on(verifyAuthFailure, (state ) => {
        return {
            ...state,
        }
    }),
    on(verifyAuthSuccess, (state, {data} ) => {
        return {
            ...state,
            user: data,
            isLoggedIn: true,
            error: ''
        }
    }),

    on(loginAuthsSuccess, (state, {data}) => {

        return {
            ...state,
            user: data,
            isLoggedIn: true,
            error: ''

        };
    }),
    on(loginAuthsFailure, (state, {error}) => {
        let err = getFirebaseCatchError(error);
        console.log(err);
        return {
            ...state,
            isLoggedIn: false,
            error: err
        };
    }),



    on(logoutAuth, (state) => {
        console.log("logoutAuth");
       return {
              ...state,
       }
    }),

    on(logoutAuthSuccess, (state, {success}) => {
        console.log("logoutAuthSuccess");
        if(success){
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            }
        }else{
            return {
                ...state,
            }
        }
    }),

);

const getFirebaseCatchError = (error: any) => {
    console.log(error);
    if(typeof error.code == 'undefined'){
        return error.message;
    }


    let message;
    if(error.code === "auth/user-not-found") {
        message = "Usuario no encontrado";
    }else if (error.code === "auth/wrong-password") {
        message = "Contraseña incorrecta";
    }else if (error.code === "auth/invalid-email") {
        message = "Correo electrónico inválido";
    } else if (error.code === "auth/email-already-in-use") {
        message = "Correo electrónico ya está en uso";
    }else if (error.code === "auth/weak-password") {
        message = "Contraseña muy débil";
    }else if (error.code === "auth/too-many-requests") {
        message = "Demasiados intentos fallidos";
    }else if (error.code === "auth/network-request-failed") {
        message = "Error de red";
    }else if (error.code === "auth/operation-not-allowed") {
        message = "Operación no permitida";
    }else if (error.code === "auth/user-disabled") {
        message = "Usuario deshabilitado";
    }else if (error.code === "auth/user-token-expired") {
        message = "Token de usuario expirado";
    }else if (error.code === "auth/web-storage-unsupported") {
        message = "Almacenamiento web no soportado";
    }else if (error.code === "auth/argument-error") {
        message = "Error de argumento";
    }else if (error.code === "auth/app-deleted") {
        message = "Aplicación eliminada";
    }else if (error.code === "auth/invalid-api-key") {
        message = "Clave API inválida";
    }else if (error.code === "auth/invalid-user-token") {
        message = "Token de usuario inválido";
    }else if (error.code === "auth/unauthorized-domain") {
        message = "Dominio no autorizado";
    }else if (error.code === "auth/invalid-continue-uri") {
        message = "URI de continuación inválida";
    }else if (error.code === "auth/invalid-dynamic-link-domain") {
        message = "Dominio dinámico de enlace inválido";
    }else if (error.code === "auth/invalid-action-code") {
        message = "Código de acción inválido";
    }else if(error.code === "auth/requires-recent-login"){
        message = "Se requiere un inicio de sesión reciente";
    }
    return  message;
}
