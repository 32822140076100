import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {DashboardDeviceType, typeRole, UserDashboardType, UserType} from "../types/users.models";
import {
  collection,
  collectionData,
  CollectionReference, doc,
  Firestore,
  query,
  updateDoc,
  where,
  arrayUnion, addDoc, getDocs,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly usersDashboardCollectionRef: CollectionReference<UserDashboardType>

  constructor(private _http: HttpClient, private readonly firestore: Firestore) {
    this.usersDashboardCollectionRef = collection(this.firestore, 'dashboard-devices').withConverter<UserDashboardType>({
      fromFirestore: (snapshot) => {
        const { user_id, name, devices } = snapshot.data() as UserDashboardType;
        const { id } = snapshot;
        return { id, user_id, name, devices};
      },
      toFirestore: (it: UserDashboardType) => it,
    });

  }

  createUser(user: UserType) {
    return this._http.post<any>(`${environment.apiAlertUrl}/user/create`, user, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'ApiKey ' + environment.ApiAlertKey
      }
    });
  }


  getDashboards(user: string) {
    return collectionData(query(this.usersDashboardCollectionRef, where('user_id', '==', user)), {idField: 'id'});
  }

  updateDashboard(dashboard: UserDashboardType) {
    return updateDoc(doc(this.usersDashboardCollectionRef, dashboard.id), {
        name: dashboard.name,
        devices: dashboard.devices
    });
  }

  updateDashboardDevices(id:string, devices: DashboardDeviceType[]) {
    return updateDoc(doc(this.usersDashboardCollectionRef, id), {
      devices: devices
    });
  }

  addDeviceToDashboard(dashboard_id: string, device: DashboardDeviceType) {
    return updateDoc(doc(this.usersDashboardCollectionRef, dashboard_id), {
        devices: arrayUnion(device)
    });
  }

  addDashboard(user: UserType, dashboard: UserDashboardType) {
    return addDoc(this.usersDashboardCollectionRef, {
        name: dashboard.name,
        devices: [],
        user_id: user.id
    });
  }

  findDashboard(user: string, name: string) {
    return getDocs(query(this.usersDashboardCollectionRef, where('user_id', '==', user), where('name', '==', name)));
  }

}
