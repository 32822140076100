import {createAction, props} from "@ngrx/store";
import {
    ActionButtonType,
    CompanyType,
    DeviceType,
    FrontSecurityType,
    HeadquartersType
} from "../../shared/types/company.model";
import {UserType} from "../../shared/types/users.models";

export const CompanyAddListCompanies = createAction(
    '[Company] Add List companies',
    props<{ user: UserType }>()
);

export const CompanyAddListCompaniesSuccess = createAction(
    '[Company] Add List companies Success',
    props<{ companies: CompanyType[] }>()
);

export const CompanyAddListCompaniesFailure = createAction(
    '[Company] Add List companies Failure',
    props<{ error: any }>()
);



export const CompanyAddListHeadquarters = createAction(
    '[Company] Add List Headquarters',
    props<{ user: UserType }>()

);

export const CompanyAddListHeadquartersSuccess = createAction(
    '[Company] Add List Headquarters Success',
    props<{ headquarters: HeadquartersType[] }>()
);

export const CompanyAddListHeadquartersFailure = createAction(
    '[Company] Add List Headquarters Failure',
    props<{ error: any }>()
);




// front security

export const CompanyAddListFrontSecurity = createAction(
    '[Company] Add List Front Security',
    props<{ user: UserType }>()

);

export const CompanyAddListFrontSecuritySuccess = createAction(
    '[Company] Add List Front Security Success',
    props<{ frontSecurities: FrontSecurityType[] }>()
);

export const CompanyAddListFrontSecurityFailure = createAction(
    '[Company] Add List Front Security Failure',
    props<{ error: any }>()
);

// devices

export const CompanyAddListDevices = createAction(
    '[Company] Add List Devices',
    props<{ user: UserType }>()
);

export const CompanyAddListDevicesSuccess = createAction(
    '[Company] Add List Devices Success',
    props<{ all_devices: DeviceType[] }>()
);

export const CompanyAddListDevicesFailure = createAction(
    '[Company] Add List Devices Failure',
    props<{ error: any }>()
);

// users

export const CompanyAddListUsers = createAction(
    '[Company] Add List Users',
    props<{ user: UserType }>()
);

export const CompanyAddListUsersSuccess = createAction(
    '[Company] Add List Users Success',
    props<{ users: UserType[] }>()
);

export const CompanyAddListUsersFailure = createAction(
    '[Company] Add List Users Failure',
    props<{ error: any }>()
);


// action-buttons

export const CompanyAddListActionButton = createAction(
    '[Company] Add List action buttons',
    props<{ user: UserType }>()
);


export const CompanyAddListActionButtonSuccess = createAction(
    '[Company] Add List action buttons Success',
    props<{ actions_buttons: ActionButtonType[] }>()
);

export const CompanyAddListActionButtonFailure = createAction(
    '[Company] Add List action buttons Failure',
    props<{ error: any }>()
);


