import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {from, of} from "rxjs";

import {ToastrService} from "ngx-toastr";
import {
    Firestore,

} from "@angular/fire/firestore";
import {
    CompanyAddListActionButton,
    CompanyAddListActionButtonFailure,
    CompanyAddListActionButtonSuccess,
    CompanyAddListCompanies,
    CompanyAddListCompaniesFailure,
    CompanyAddListCompaniesSuccess,
    CompanyAddListDevices, CompanyAddListDevicesFailure, CompanyAddListDevicesSuccess,
    CompanyAddListFrontSecurityFailure,
    CompanyAddListFrontSecuritySuccess,
    CompanyAddListHeadquarters,
    CompanyAddListHeadquartersFailure,
    CompanyAddListHeadquartersSuccess, CompanyAddListUsers, CompanyAddListUsersFailure, CompanyAddListUsersSuccess
} from "../actions/company.actions";
import {CompanyService} from "../../shared/services/company.service";


@Injectable()
export class CompanyEffects {

    constructor(
                private actions$: Actions,
                private _toastService: ToastrService,
                private readonly firestore: Firestore,
                private companyService: CompanyService

    ) {
    }

    CompanyAddListCompanies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAddListCompanies),
            switchMap(action =>
                from(this.companyService.getCompanies(action.user)).pipe(
                    map(data => CompanyAddListCompaniesSuccess({companies: data})),
                    catchError(err => of(CompanyAddListCompaniesFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    CompanyAddListHeadquarters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAddListHeadquarters),
            switchMap(action =>
                from(this.companyService.getHeadquarters(action.user)).pipe(
                    map(data => CompanyAddListHeadquartersSuccess({headquarters: data})),
                    catchError(err => of(CompanyAddListHeadquartersFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    // front security

    CompanyAddListFrontSecurity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAddListHeadquarters),
            switchMap(action =>
                from(this.companyService.getFrontSecurities(action.user)).pipe(
                    map(data => CompanyAddListFrontSecuritySuccess({frontSecurities: data})),
                    catchError(err => of(CompanyAddListFrontSecurityFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    CompanyAddListDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAddListDevices),
            switchMap(action =>
                from(this.companyService.getDevices(action.user)).pipe(
                    map(data => CompanyAddListDevicesSuccess({all_devices: data})),
                    catchError(err => of(CompanyAddListDevicesFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );


    CompanyAddListUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAddListUsers),
            switchMap(action =>
                from(this.companyService.getUsers(action.user)).pipe(
                    map(data => CompanyAddListUsersSuccess({users: data})),
                    catchError(err => of(CompanyAddListUsersFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    CompanyAddListActionButtons$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyAddListActionButton),
            switchMap(action =>
                from(this.companyService.getActionButtons(action.user)).pipe(
                    map(data => CompanyAddListActionButtonSuccess({actions_buttons: data})),
                    catchError(err => of(CompanyAddListActionButtonFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );
}
