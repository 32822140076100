import {createAction, props} from "@ngrx/store";
import {AlertType} from "../../../@core/types/utils";
import {UserType} from "../../shared/types/users.models";

export const AlertAddListAlerts = createAction(
    '[Alert] Add List Alerts',
    props<{ user: UserType }>()

);

export const AlertAddListAlertsSuccess = createAction(
    '[Alert] Add List Alerts Success',
    props<{ alerts: AlertType[] }>()
);

export const AlertAddListAlertsFailure = createAction(
    '[Alert] Add List Alerts Failure',
    props<{ error: any }>()
);
