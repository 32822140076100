import {createReducer, on} from '@ngrx/store';

import {AlertType} from "../../../@core/types/utils";
import {AlertAddListAlerts, AlertAddListAlertsSuccess} from "../actions/alert.actions";



export const authFeatureKey = 'auth';

export interface State {
    error: string;
    alerts: AlertType[];

}

export const initialState: State = {
    error: '',
    alerts: [],

};

export const reducer = createReducer(
    initialState,
    on(AlertAddListAlerts, (state) => {
        return {
            ...state,
            alerts: [],
            error: ''
        };

    }),
    on(AlertAddListAlertsSuccess, (state, {alerts} ) => {
        return{
            ...state,
            error: '',
            alerts: alerts
        }
    }),

);

