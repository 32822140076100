export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      DASHBOARDS: {
        ANALYTICS: 'Analytics',
        SENSORS: 'Sensors',
        ALERTS: 'Alerts'
      },
      SAMPLE: 'Sample',
      CHECK: 'Check',
      COMPANY:{
        ALL: 'Companies'
      },
      ACTION_BUTTON: "Action button",
      CONFIG:{
        LOCALITY: 'Localities',
        NEIGHBORHOOD: 'Neighborhoods',
        TYPE_ALERT: 'Alert types',
        TYPE_SENSOR: 'Sensor types',
        TYPE_DEVICE: 'Device types',
        DEVICES: 'DEVICES'
      }
    }
  }
}
