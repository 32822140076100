declare var ConnectyCube: any;


import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {
    loginAuths,
    loginAuthsFailure,
    loginAuthsSuccess, logoutAuth, logoutAuthFailure, logoutAuthSuccess,
    verifyAuth, verifyAuthFailure, verifyAuthSuccess
} from "../actions/auth.actions";
import {AuthenticationService} from "../../auth/service";
import {from, of} from "rxjs";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import * as AppState from "../index";
import {
    AppAddListDevicesAdmin,
    AppAddListLocalities,
    AppAddListNeighborhoods,
    AppAddListTypeAlerts,
    AppAddListTypeDevices, AppAddListTypeLocationsDevices, AppAddListTypeSensors
} from "../actions/app.actions";
import {AlertAddListAlerts} from "../actions/alert.actions";
import {
    CompanyAddListActionButton,
    CompanyAddListCompanies, CompanyAddListDevices,
    CompanyAddListFrontSecurity,
    CompanyAddListHeadquarters, CompanyAddListUsers
} from "../actions/company.actions";
import {typeRole, UserType} from "../../shared/types/users.models";
import {DashboardAddListAlerts, DashboardsDevicesUser} from "../actions/dashboard.actions";


@Injectable()
export class AuthEffects {


    loadData(user: UserType){
        this.store.dispatch(AppAddListTypeDevices());
        this.store.dispatch(AppAddListTypeLocationsDevices());
        this.store.dispatch(AppAddListLocalities());
        this.store.dispatch(AppAddListNeighborhoods());
        this.store.dispatch(AppAddListTypeAlerts());
        this.store.dispatch(AppAddListTypeSensors());
        this.store.dispatch(CompanyAddListCompanies({user}));
        this.store.dispatch(CompanyAddListHeadquarters({user}));
        this.store.dispatch(CompanyAddListFrontSecurity({user}));
        this.store.dispatch(CompanyAddListDevices({user}));
        this.store.dispatch(DashboardsDevicesUser({userId: user.id}));
        //if(user.sede_id == null || user.role === typeRole.super_admin){
          //  this.store.dispatch(AlertAddListAlerts({user}));
        //}
        this.store.dispatch(AlertAddListAlerts({user}));

        // subtraction 30 days
        let start = new Date();
        start.setDate(start.getDate() - 30);
        start.setHours(0,0,0,0);
        let end = new Date();
        end.setHours(23,59,59,999);
        this.store.dispatch(DashboardAddListAlerts({user, start, end}));
        this.store.dispatch(CompanyAddListUsers({user}));
        this.store.dispatch(AppAddListDevicesAdmin());
        this.store.dispatch(CompanyAddListActionButton({user}));

        this.connectCubeSession();



    }


    async connectCubeSession(){
        try{
            const session = await ConnectyCube.createSession()
            const idToken = await this.authenticationService.currentUserValue.getIdToken()
            console.log('idToken', idToken)
            const userCredentials = {
                provider: 'firebase_email',
                firebase_email: {
                    project_id: 'helpsmart-urbanizaciones',
                    access_token: idToken
                }
            };
            const user = await ConnectyCube.login(userCredentials)

            console.log('user', user)




        }catch (e) {
            console.log(e);
        }
    }




  constructor(
      private actions$: Actions,
      private authenticationService: AuthenticationService,
      private router: Router,
      private store: Store<AppState.State>
      ) {}

    login$ = createEffect(() =>
      this.actions$.pipe(
          ofType(loginAuths),
          switchMap(action =>
              from(this.authenticationService.login(action.email, action.password)).pipe(
                  map(data => loginAuthsSuccess({data})),
                  catchError(err => of(loginAuthsFailure({ error: err })))
              )
          )
      ), { dispatch: true }
    );

    verify$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyAuth),
            switchMap(action =>
                from(this.authenticationService.verifyAuth()).pipe(
                    map(data => verifyAuthSuccess({data})),
                    catchError(err => of(verifyAuthFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    verifiedAuthSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyAuthSuccess),
            tap((d) => {
                this.loadData(d.data);

            }),
        ), { dispatch: false }
    );

    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginAuthsSuccess),
            tap((d) => {
                this.router.navigate(['/admin/dashboards/alert']);
                this.loadData(d.data);


            }),
        ), { dispatch: false }
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logoutAuth),
            switchMap(action =>
                from(this.authenticationService.logout()).pipe(
                    map(data => logoutAuthSuccess({success: (data) })),
                    catchError(err => of(logoutAuthFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    logoutSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logoutAuthSuccess),
            tap(() => {
                this.router.navigate(['/auth/login']);
            }),
        ), { dispatch: false }
    );


}
