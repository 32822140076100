import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AppState  from "../../store"
import {typeRole} from "../../shared/types/users.models";

export const selectApp = (state: AppState.State) => state.company;
export const selectAppAuth = (state: AppState.State) => state.auth;


export const selectCompanies = createSelector(
    selectApp,
    (state) => state.companies
);

export const selectCompaniesCount = createSelector(
    selectApp,
    (state) => state.companies.length
);


export const selectHeadquarters = createSelector(
    selectApp,
    (state) => state.headquarters
);

export const selectHeadquartersByCompany = createSelector(
    selectHeadquarters,
    (state, props) => state.filter((v) => v.company_id === props.company)
);

export const selectHeadquartersCountByCompany = createSelector(
    selectHeadquarters,
    (state, props) => state.filter((v) => v.company_id === props.company).length
);


export const selectFrontSecurities = createSelector(
    selectApp,
    (state) => state.front_securities
);

export const selectFrontSecuritiesByCompany = createSelector(
    selectFrontSecurities,
    (state, props) => state.filter((v) => v.company_id === props.company)
);

export const selectFrontSecuritiesCountByCompany = createSelector(
    selectFrontSecurities,
    (state, props) => state.filter((v) => v.company_id === props.company).length
);



export const selectDevices = createSelector(
    selectApp,
    (state) => state.devices
);

export const selectDevicesByCompany = createSelector(
    selectDevices,
    (state, props) => state.filter((v) => v.company_id === props.company)
);

export const selectDevicesCountByCompany = createSelector(
    selectDevices,
    (state, props) => state.filter((v) => v.company_id === props.company).length
);



export const companyUser = createSelector(
    selectApp,
    selectAppAuth,
    (s1, s2) => (s2.user?.company !== null && s2.user?.role != typeRole.super_admin) ? s1.companies.find((v) => v.id === s2.user?.company) : null
);


export const selectUsers = createSelector(
    selectApp,
    (state) => state.users,
);

export const selectUserByCompany = createSelector(
    selectApp,
    (state, props) => state.users.filter((v) => v.company === props.company),
);

export const selectUsersByRole = createSelector(
    selectApp,
    (state, props) => state.users.filter((v) => v.role === props.role),
);


export const selectActionsButtons = createSelector(
    selectApp,
    (state) => state.action_buttons
);