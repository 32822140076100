import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  Firestore, getDocs,
  orderBy,
  query,
  where
} from "@angular/fire/firestore";
import {AlertType, AlertStatusEnum} from "../../../@core/types/utils";
import {typeRole, UserType} from "../types/users.models";
import { addDoc, serverTimestamp } from 'firebase/firestore';
import { exec } from 'child_process';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private readonly alertsCollectionRef: CollectionReference<AlertType>


  constructor(private readonly firestore: Firestore) {
    this.alertsCollectionRef = collection(firestore, 'alerts').withConverter<AlertType>({
      fromFirestore: (snapshot) => {
        const { mac, comment_operator, user_operator, user, created_at, company_id, front_security,
          description, media_type, media_url, type, position, status, updated_at, cancel_reason, 
          attempts, client_id  } = snapshot.data() as AlertType;
        const { id } = snapshot;
        return { id, mac, comment_operator, user_operator, user, created_at, company_id, front_security,
          description, media_type, media_url, type, position, status, updated_at, cancel_reason, attempts, client_id } as AlertType;
      },
      toFirestore: (it: AlertType) => it,
    });

  }


  getAlerts(user:UserType) {
    console.log(" user  alert", user);
    if (user.role === typeRole.super_admin) {
      return collectionData(query(this.alertsCollectionRef,
          where('status', 'in', [AlertStatusEnum.PENDING, AlertStatusEnum.CANCEL_USER, AlertStatusEnum.ON_PROCESS]),
          orderBy('created_at', 'desc')));
    }else if(user.role === typeRole.admin){
      return collectionData(query(this.alertsCollectionRef,
          where('status', 'in', [AlertStatusEnum.PENDING, AlertStatusEnum.CANCEL_USER, AlertStatusEnum.ON_PROCESS]),
          where('company_id', '==', user.company),
          orderBy('created_at', 'desc')));
    }else if(user.role === typeRole.operator) {

      let queryRef = query(this.alertsCollectionRef,
            where('status', 'in', [AlertStatusEnum.PENDING, AlertStatusEnum.CANCEL_USER, AlertStatusEnum.ON_PROCESS]),
            where('company_id', '==', user.company),
            orderBy('created_at', 'desc'));

      if(user.sede_id){
        queryRef = query(this.alertsCollectionRef,
            where('status', 'in', [AlertStatusEnum.PENDING, AlertStatusEnum.CANCEL_USER, AlertStatusEnum.ON_PROCESS]),
            where('company_id', '==', user.company),
            //where('headquarter_id', '==', user.sede_id),
            orderBy('created_at', 'desc'));
      }
      return collectionData(queryRef);
    }
  }


  getAlertsDashboard(user: UserType, start: Date, end: Date) {


    if (user.role === typeRole.super_admin) {
      return collectionData(query(this.alertsCollectionRef,
          where('created_at', '>=', start),
          where('created_at', '<=', end),
          orderBy('created_at', 'desc'))
      );
    }else if(user.role === typeRole.admin){
        return collectionData(query(this.alertsCollectionRef,
            where('created_at', '>=', start),
            where('created_at', '<=', end),
            where('company_id', '==', user.company),
            orderBy('created_at', 'desc'))
        );
    }else {
        return collectionData(query(this.alertsCollectionRef,
            where('created_at', '>=', start),
            where('created_at', '<=', end),
            where('company_id', '==', user.company),
            orderBy('created_at', 'desc'))
        );
    }

  }


  createLogDeviceCalll(client_id: number, action: string){
    addDoc(collection(this.firestore, 'log_device_call'), {
      client_id: client_id,
      action: action,
      created_at: serverTimestamp(),
      executed: false
    });
  }


}
