import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';

import * as authState  from "./reducers/auth.reducer"
import * as appState  from "./reducers/app.reducer"
import * as alertState  from "./reducers/alert.reducer"
import * as companyState  from "./reducers/company.reducer"
import * as dashboardState  from "./reducers/dashboard.reducer"


export interface State {
  auth: authState.State,
  app: appState.State,

  alert: alertState.State,
  company: companyState.State,

  dashboard: dashboardState.State,

}

export const reducers: ActionReducerMap<State> = {
  auth: authState.reducer,
  app: appState.reducer,
  alert: alertState.reducer,
  company: companyState.reducer,
  dashboard: dashboardState.reducer,
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

// selectors

export const selectAuthState = createFeatureSelector<authState.State>('auth');
export const selectAppState = createFeatureSelector<appState.State>('app');
export const selectAlertState = createFeatureSelector<alertState.State>('alert');
export const selectCompanyState = createFeatureSelector<companyState.State>('company');
export const selectDashboardState = createFeatureSelector<dashboardState.State>('dashboard');


export const selectAdminDevices = createSelector(
    selectAppState,
    (state: appState.State) => state.admin_devices
);