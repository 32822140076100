import {createReducer, on} from '@ngrx/store';
import {
    AppAddListDevicesAdmin, AppAddListDevicesAdminSuccess,
    AppAddListLocalities,
    AppAddListLocalitiesSuccess,
    AppAddListNeighborhoods,
    AppAddListNeighborhoodsSuccess,
    AppAddListTypeAlerts,
    AppAddListTypeAlertsSuccess, AppAddListTypeDevices, AppAddListTypeDevicesSuccess,
    AppAddListTypeLocationsDevices,
    AppAddListTypeLocationsDevicesSuccess, AppAddListTypeSensors, AppAddListTypeSensorsSuccess
} from "../actions/app.actions";
import {
    LocalityType,
    LocationDeviceType,
    NeighborhoodType,
    TypeAlertType,
    TypeDeviceType, TypeSensorType
} from "../../shared/types/config.model";
import {AdminDeviceType} from "../../shared/types/company.model";



export const authFeatureKey = 'auth';

export interface State {
    error: string;
    neighborhoods: NeighborhoodType[];
    localities: LocalityType[];
    alertTypes: TypeAlertType[];
    type_devices: TypeDeviceType[];
    location_devices: LocationDeviceType[];
    type_sensors: TypeSensorType[];
    admin_devices: AdminDeviceType[];
}

export const initialState: State = {
    error: '',
    neighborhoods: [],
    localities: [],
    alertTypes: [],
    type_devices: [],
    location_devices: [],
    type_sensors: [],
    admin_devices: []
};

export const reducer = createReducer(
    initialState,
    on(AppAddListNeighborhoods, (state) => {
        return {
          ...state,
            error: ''
        };

    }),
    on(AppAddListNeighborhoodsSuccess, (state, {neighborhoods} ) => {
        return{
            ...state,
            error: '',
            neighborhoods: neighborhoods
        }

    }),
    on(AppAddListLocalities, (state) => {
        return {
            ...state,
            localities: [],
            error: ''
        };

    }),
    on(AppAddListLocalitiesSuccess, (state, {localities} ) => {
        return{
            ...state,
            error: '',
            localities: localities
        }

    }),

    on(AppAddListTypeAlerts, (state) => {
        return {
            ...state,
            localities: [],
            error: ''
        };

    }),
    on(AppAddListTypeAlertsSuccess, (state, {type_alerts} ) => {
        return{
            ...state,
            error: '',
            alertTypes: type_alerts
        }
    }),
    // locations devices

    on(AppAddListTypeLocationsDevices, (state) => {
        return {
            ...state,
            location_devices: [],
            error: ''
        };

    }),
    on(AppAddListTypeLocationsDevicesSuccess, (state, {type_locations_device} ) => {
        return{
            ...state,
            error: '',
            location_devices: type_locations_device
        }
    }),
    // types devices

    on(AppAddListTypeDevices, (state) => {
        return {
            ...state,
            type_devices: [],
            error: ''
        };

    }),
    on(AppAddListTypeDevicesSuccess, (state, {type_devices} ) => {
        return{
            ...state,
            error: '',
            type_devices: type_devices
        }
    }),

    on(AppAddListTypeSensors, (state) => {
        return {
            ...state,
            type_sensors: [],
            error: ''
        };
    }),

    on(AppAddListTypeSensorsSuccess, (state, {type_sensors} ) => {
        return{
            ...state,
            error: '',
            type_sensors: type_sensors
        }
    }),

    on(AppAddListDevicesAdmin, (state) => {
        return {
            ...state,
            admin_devices: [],
        };
    }),

    on(AppAddListDevicesAdminSuccess, (state, {devices} ) => {
        return{
            ...state,
            error: '',
            admin_devices: devices
        }
    }),


);

