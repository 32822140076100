import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from "rxjs/operators";

import {from, of} from "rxjs";

import {ToastrService} from "ngx-toastr";
import {
    Firestore,
} from "@angular/fire/firestore";
import {AlertAddListAlerts, AlertAddListAlertsFailure, AlertAddListAlertsSuccess} from "../actions/alert.actions";
import {AlertService} from "../../shared/services/alert.service";


@Injectable()
export class AlertEffects {
    constructor(private actions$: Actions,
                private _toastService: ToastrService,
                private readonly firestore: Firestore,
                private readonly alertService: AlertService

    ) {

    }

    AlertAddListAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AlertAddListAlerts),
            switchMap(action =>
                from(this.alertService.getAlerts(action.user)).pipe(
                    map(data => AlertAddListAlertsSuccess({alerts: data})),
                    catchError(err => of(AlertAddListAlertsFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

}
