
import {GeoPoint, Timestamp} from "@angular/fire/firestore";



export enum AlertStatusEnum {
    PENDING = 'PENDING',
    ON_PROCESS = 'ON_PROCESS',
    CANCEL_USER = 'CANCEL_USER',
    CANCEL = 'CANCEL',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    FINISHED = 'FINISHED',
}

export enum AlertTypeEnum {
    VIOLENCIA = 'Violencia_Domestica',
    HURTO = 'Hurto',
    INCENDIO = 'Incendio',
    PANICO = 'Panico',
    CONTAMINACION_AUDIOVISUAL = 'Contaminacion_Audiovisual',
    SOLICITUD_DE_MANTENIMIENTO = 'Solicitud_de_Mantenimiento',
    REPORTE_AMBIENTAL = 'Reporte_Ambiental',
    EMERGENCIA_EN_SALUD = 'Emergencia_En_Salud',
}

export enum MediaTypeEnum {
    IMAGE = 'image',
    VIDEO = 'video',
    SOUND = 'sound',
}

interface UserAlert {
    id:    string;
    name:  string;
    nit:   string;
    phone: number;
}



export type AlertType = {
    comment_operator?:string;
    id?:            string;
    type:           string;
    description?:   string;
    media_type?:    MediaTypeEnum;
    media_url?:     string;
    status?:        AlertStatusEnum;
    front_security?: string;
    company_id:     string;
    cancel_reason?: string;
    created_at:     Timestamp;
    updated_at?:    Timestamp;
    user:           UserAlert;
    user_operator?: UserAlert;
    position:       GeoPoint;
    mac?:           string;

    attempts?:      number
    client_id?:     number

}