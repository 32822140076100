// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'helpsmart-urbanizaciones',
    appId: '1:611779201540:web:95d7f5cec7a46af92c4351',
    databaseURL: 'https://helpsmart-urbanizaciones-default-rtdb.firebaseio.com',
    storageBucket: 'helpsmart-urbanizaciones.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBOIl4GsMfWIyqbiXdENt0LvJR0uyNdy-c',
    authDomain: 'helpsmart-urbanizaciones.firebaseapp.com',
    messagingSenderId: '611779201540',
    measurementId: 'G-EH2Z48V5N4',
  },
  production: false,
  hmr: false,
  apiUrl: 'https://dev-api.helpsmart.co',
  apiAlertUrl: 'https://api-broker-emergencias.helpsmart.co',
  ApiAlertKey: "29315DD6A-9759F5-797BCB1-599A935sd2ww",
  apiUrlGraphql: 'https://api-broker-emergencias.helpsmart.co/graphql',
  apiUrlGraphqlWss: 'wss://api-broker-emergencias.helpsmart.co/graphql',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
