import { createAction, props } from '@ngrx/store';
import {UserType} from "../../shared/types/users.models";

export const loginAuths = createAction(
  '[Auth] Login Auths',
    props<{ email: string; password: string }>()
);

export const loginAuthsSuccess = createAction(
  '[Auth] Login Auths Success',
  props<{ data: UserType }>()
);

export const loginAuthsFailure = createAction(
  '[Auth] Login Auths Failure',
  props<{ error: any }>()
);

export const verifyAuth = createAction(
    '[Auth] Verify Auth user'
);
export const verifyAuthFailure = createAction(
    '[Auth] Verify Auth user Failure',
    props<{ error: any }>()
);


export const verifyAuthSuccess = createAction(
    '[Auth] Verify Auth user Success',
    props<{ data: UserType }>()
);


export const logoutAuth = createAction(
    '[Auth] Logout Auth',
);

export const logoutAuthSuccess = createAction(
    '[Auth] Logout Auth Success',
    props<{ success: boolean }>()
);

export const logoutAuthFailure = createAction(
    '[Auth] Logout Auth Failure',
    props<{ error: any }>()
);


