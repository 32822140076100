import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLicense } from '@syncfusion/ej2-base';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';

registerLicense('Mgo+DSMBaFt/QHRqVVhkXlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH5Xd01nUHxaeXNURA==;Mgo+DSMBPh8sVXJ0S0J+XE9AdFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0VnWHdaeXdRT2hYUw==;ORg4AjUWIQA/Gnt2VVhkQlFacl1JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjXX5WdH1WQmlVVkY=;ODc3MDM3QDMyMzAyZTM0MmUzMERDSGkzc2hCc2R5clplek1oOUpqYlJVNHg2NEpNREZDS2xDSnMxTzkwZlU9;ODc3MDM4QDMyMzAyZTM0MmUzMEVmcjY4aWhhM1Raekc0S3dtMnIyRE9FUE4yZCtjUWVLdCt4My9tVS91NzA9;NRAiBiAaIQQuGjN/V0Z+WE9EaFtAVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWXtfeHBcRWVcV0d0;ODc3MDQwQDMyMzAyZTM0MmUzMFlvNlNRdldydUJtWGlvWGJkR3ZqeC9aRzA1VFVoeHRpK3NKdjZuTFMyTnM9;ODc3MDQxQDMyMzAyZTM0MmUzMEFIRVZHMGNoc2RnczF3eTgwaUNSL1BVSDhDZC8rcHNYQVRIS3NDSngveUk9;Mgo+DSMBMAY9C3t2VVhkQlFacl1JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjXX5WdH1WQ2FeV0Y=;ODc3MDQzQDMyMzAyZTM0MmUzMFVLclZWZnJnbHg1QXpUaGg5WnlkMEdTZ3Qvem1PZmZJdkdUK1VUU2t3MUk9;ODc3MDQ0QDMyMzAyZTM0MmUzMFVuSlA4UUpuQWM5TDZ4MnpUbXMyd1crZUl1dEVUU1E5T0R4eU5WMndEOUE9;ODc3MDQ1QDMyMzAyZTM0MmUzMFlvNlNRdldydUJtWGlvWGJkR3ZqeC9aRzA1VFVoeHRpK3NKdjZuTFMyTnM9');


if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}
