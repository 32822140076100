import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import {getAuth, provideAuth} from "@angular/fire/auth";
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './store';
import {getStorage, provideStorage} from "@angular/fire/storage";
import {EffectsModule} from "@ngrx/effects";
import {AuthEffects} from "./store/effects/auth.effects";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {getPerformance, providePerformance } from "@angular/fire/performance";
import {AppEffects} from "./store/effects/app.effects";
import {AlertEffects} from "./store/effects/alert.effects";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {CompanyEffects} from "./store/effects/company.effects";
import {canActivate, redirectUnauthorizedTo} from "@angular/fire/auth-guard";
import {NgxMaskModule} from "ngx-mask";
import {DashboardLayoutModule} from "@syncfusion/ej2-angular-layouts";
import {DashboardEffects} from "./store/effects/dashboard.effects";

import {

  ColumnSeriesService, DateTimeService, LineSeriesService,
} from "@syncfusion/ej2-angular-charts";
import {GraphQLModule} from "./graphql.module";






export function playerFactory() {
    return player;
}

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth/login']);


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/admin/dashboards/alert',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'auth',
    loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'miscellaneous',
    loadChildren: () => import('./main/pages/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule),
  },

  {
    path: 'chart',
    loadChildren: () => import('./main/chart/chart.module').then(m => m.ChartModule),
  },

  {
    path: '**',
    redirectTo: '/miscellaneous/error', //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  providers: [
    ColumnSeriesService, DateTimeService, LineSeriesService
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
    providePerformance(() => getPerformance()),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([ AuthEffects, AppEffects, AlertEffects, CompanyEffects, DashboardEffects]),
    FontAwesomeModule,
    LottieModule.forRoot({ player: playerFactory }),
    ReactiveFormsModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    NgxMaskModule.forRoot(),
    DashboardLayoutModule,
    GraphQLModule,

  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
