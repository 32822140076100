<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    Helpmedica &copy; {{ year }}
  </span>
<!--  <span class="float-md-right d-none d-md-block">-->
<!--    Hand-crafted & Made with <span [data-feather]="'heart'" [class]="'pink'"></span>-->
<!--  </span>-->
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<div class="buy-now">
  <a href="https://1.envato.market/vuexy_admin" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow"
    >Buy Now</a
  >
</div>
