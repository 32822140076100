import {CoreMenu} from '@core/types'
import {typeRole} from "../shared/types/users.models";

export const menu: CoreMenu[] = [
  {
    id: 'dashboards',
    title: 'Tableros',
    type: 'collapsible',
    icon: 'home',
    role: [typeRole.admin, typeRole.operator, typeRole.super_admin],
    children: [
      {
        id: 'analytics',
        title: 'Analitca',
        translate: 'MENU.DASHBOARDS.ANALYTICS',
        type: 'item',
        url: 'admin/dashboards/analytics',
        icon: 'monitor',
        role: [typeRole.admin, typeRole.super_admin],
      },
      {
        id: 'sensors',
        title: 'Sensores',
        translate: 'MENU.DASHBOARDS.SENSORS',
        type: 'item',
        url: 'admin/dashboards/sensors',
        icon: 'radio',
        role: [typeRole.admin, typeRole.super_admin, typeRole.operator],
      },
      {
        id: 'alerts',
        title: 'Alertas',
        translate: 'MENU.DASHBOARDS.ALERTS',
        type: 'item',
        url: 'admin/dashboards/alert',
        icon: 'alert-circle',
        role: [typeRole.admin, typeRole.super_admin, typeRole.operator],
      }
    ]
  },
  {
    id: 'my_company',
    title: 'Mi empresa',
    type: 'item',
    icon: 'briefcase',
    role: [typeRole.admin],
    url: 'admin/mi-empresa',
  },
  {
    id: 'company',
    title: 'Empresas',
    type: 'collapsible',
    icon: 'briefcase',
    role: [typeRole.super_admin],
    children: [
      {
        id: 'company_all',
        title: 'Empresas',
        translate: 'MENU.COMPANY.ALL',
        url: 'admin/empresas',
        type: 'item',
        role: [typeRole.super_admin],
      }
    ]
  },
  {
    id: 'config',
    title: 'Configuración',
    type: 'collapsible',
    icon: 'settings',
    role: [typeRole.super_admin],
    children: [
      {
        id: 'locality',
        title: 'Localidades',
        translate: 'MENU.CONFIG.LOCALITY',
        url: 'admin/configuracion/localidades',
        type: 'item',
        icon: 'map-pin',
        role: [typeRole.super_admin],
      },
      {
        id: 'neighborhood',
        title: 'Barrios',
        translate: 'MENU.CONFIG.NEIGHBORHOOD',
        url: 'admin/configuracion/barrios',
        type: 'item',
        icon: 'map-pin',
        role: [typeRole.super_admin],
      },
      {
        id: 'type_alert',
        title: 'Tipos de alertas',
        translate: 'MENU.CONFIG.TYPE_ALERT',
        url: 'admin/configuracion/tipos_alertas',
        type: 'item',
        icon: 'alert-circle',
        role: [typeRole.super_admin],
      },
      {
        id: 'type_sensor',
        title: 'Tipos de sensores',
      translate: 'MENU.CONFIG.TYPE_SENSOR',
        url: 'admin/configuracion/tipos_sensores',
        type: 'item',
        icon: 'activity',
        role: [typeRole.super_admin],
      },
      {
        id: 'type_device',
        title: 'Tipos de dispositivos',
        translate: 'MENU.CONFIG.TYPE_DEVICE',
        url: 'admin/configuracion/tipos_dispositivos',
        type: 'item',
        icon: 'activity',
        role: [typeRole.super_admin],
      },
      {
        id: 'devices',
        title: 'Dispositivos',
        translate: 'MENU.CONFIG.DEVICES',
        url: 'admin/configuracion/dispositivos',
        type: 'item',
        icon: 'activity',
        role: [typeRole.super_admin],
      }
    ]
  },
  {
    id: 'action-button',
    title: 'Botones de acción',
    translate: 'MENU.CHECK',
    type: 'item',
    icon: 'activity',
    url: 'admin/action-button',
    role: [typeRole.admin],
  },
  {
    id: 'check',
    title: 'Pruebas',
    translate: 'MENU.CHECK',
    type: 'item',
    icon: 'map',
    url: 'admin/check',
    role: [typeRole.super_admin, typeRole.operator, typeRole.admin],
  },

]
