import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import {CoreMenu} from "../../types";
import {User} from "@angular/fire/auth";

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: User;

  @Input()
  layout = 'vertical';

  @Input()
  menu: CoreMenu[];

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    //this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {

      setTimeout(() => {
        this.currentUser = this._coreMenuService.currentUser;

        console.log(this.currentUser);

        // Load menu
        let menu1 = this._coreMenuService.getCurrentMenu() as CoreMenu[];

        // get role from user custom claims
        this.currentUser?.getIdTokenResult().then((idTokenResult) => {
          console.log(idTokenResult);
            let role = idTokenResult.claims.role;

            // filter menu items by role and childrens by role
            this.menu = menu1.filter((item) => {
              if (item.role) {
                return item.role.includes(role);
              }
              return true;
            }).map((item) => {
                if (item.children) {
                    item.children = item.children.filter((child) => {
                    if (child.role) {
                        return child.role.includes(role);
                    }
                    return true;
                    });
                }
                return item;
            });
        });

        this._changeDetectorRef.markForCheck();
      }, 1000);

    });
  }
}
