import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';

import {ToastrService} from 'ngx-toastr';
import {Auth, authState, signInWithEmailAndPassword, signOut, User} from '@angular/fire/auth';
import {typeRole, UserType} from "../../shared/types/users.models";
import {doc, Firestore, getDoc} from "@angular/fire/firestore";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  public currentUserFirebase: Observable<User>;

  //private

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   * @param _authFire
   * @param db
   */
  constructor(
      private _http: HttpClient,
      private _toastrService: ToastrService,
      private _authFire: Auth,
      private readonly db: Firestore
  ) {
    this.currentUser = this.checkAuthStateFirebase();

    this.currentUserFirebase = authState(this._authFire);

  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this._authFire.currentUser;
  }
  /**
   * User login
   *
   * @param email
   * @param password
   * @param admin
   * @returns user
   */
  async login(email: string, password: string): Promise<UserType> {
      try {

        const user = await signInWithEmailAndPassword(this._authFire, email, password);
        console.log(user.user);
        const userRef = await getDoc(doc(this.db, `users`, user.user.uid));

        if(userRef.exists()) {
            const userDoc = userRef.data() as UserType;
            console.log(userDoc);

            if(userDoc.active && userDoc.role !== typeRole.user) {
                return {...userDoc, id: user.user.uid};
            } else {
                this._toastrService.error('User not active', 'Error');
                await signOut(this._authFire);
                throw new Error("No tiene permisos para ingresar");
            }

        }else{
           await signOut(this._authFire);
            throw new Error("User not found");
        }

      }catch (e) {
          console.log(e);
          throw new TypeError(e.message);
      }
  }
  checkAuthStateFirebase(): Observable<User> {
    return authState(this._authFire);
  }
  /**
   * User logout
   *
   */
  async logout(): Promise<boolean> {

      try {
          await signOut(this._authFire);
          return true;
      } catch (e) {
          console.log(e);
          return false;
      }
  }


  async verifyAuth(): Promise<UserType> {
        try {
            //await seconds(2);

            await new Promise(r => setTimeout(r, 1000));



            const user = this._authFire.currentUser;

            console.log(user);
            if(user == null) {
                console.log("No user");
                throw new Error("User not found");


            }

            const userRef = await getDoc(doc(this.db, `users`, user.uid));

            if(userRef.exists()) {
                const userDoc = userRef.data() as UserType;
                console.log(userDoc);

                if(userDoc.active && userDoc.role !== typeRole.user) {
                    return {...userDoc, id: user.uid};
                }
                else {
                    this._toastrService.error('User not active', 'Error');
                    await signOut(this._authFire);
                    throw new Error("User not found");

                }
            }else{
                await signOut(this._authFire);
                throw new Error("User not found");
            }

        }catch (e) {
            throw new TypeError(e.message);
        }

  }

}
