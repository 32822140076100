import {createAction, props} from "@ngrx/store";
import {AlertType} from "../../../@core/types/utils";
import {DashboardDeviceType, UserDashboardType, UserType} from "../../shared/types/users.models";

export const DashboardAddListAlerts = createAction(
    '[Dashboard] Add List Alerts',
    props<{ start: Date, end: Date, user: UserType }>()
);

export const DashboardAddListAlertsSuccess = createAction(
    '[Dashboard] Add List Alerts Success',
    props<{ alerts: AlertType[] }>()
);

export const DashboardAddListAlertsFailure = createAction(
    '[Dashboard] Add List Alerts Failure',
    props<{ error: any }>()
);


export const DashboardsDevicesUser = createAction(
    '[Dashboard] Dashboards User devices',
    props<{ userId: string }>()
);

export const DashboardsUserDevicesSuccess = createAction(
    '[Dashboard] Dashboards User devices Success',
    props<{ dashboards: UserDashboardType[] }>()
);

export const DashboardsUserDevicesFailure = createAction(
    '[Dashboard] Dashboards User devices Failure',
    props<{ error: any }>()
);
