import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AppState  from "../../store"

export const selectAuth = (state: AppState.State) => state.auth;


export const selectAuthIsLoggedIn = createSelector(
    selectAuth,
    (state) => state.isLoggedIn
);

export const selectAuthUser = createSelector(
    selectAuth,
    (state) => state.user
);

export const selectAuthError = createSelector(
    selectAuth,
    (state) => state.error
);