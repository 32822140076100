import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {catchError, map, switchMap} from "rxjs/operators";

import {from, of} from "rxjs";
import {
    AppAddListDevicesAdmin, AppAddListDevicesAdminFailure, AppAddListDevicesAdminSuccess,
    AppAddListLocalities,
    AppAddListLocalitiesFailure,
    AppAddListLocalitiesSuccess,
    AppAddListNeighborhoods,
    AppAddListNeighborhoodsFailure,
    AppAddListNeighborhoodsSuccess,
    AppAddListTypeAlerts,
    AppAddListTypeAlertsFailure,
    AppAddListTypeAlertsSuccess, AppAddListTypeDevices, AppAddListTypeDevicesFailure,
    AppAddListTypeDevicesSuccess,
    AppAddListTypeLocationsDevices, AppAddListTypeLocationsDevicesFailure,
    AppAddListTypeLocationsDevicesSuccess, AppAddListTypeSensors, AppAddListTypeSensorsSuccess
} from "../actions/app.actions";
import {CompanyService} from "../../shared/services/company.service";
import {ToastrService} from "ngx-toastr";
import {collection, collectionData, Firestore, CollectionReference, Timestamp} from "@angular/fire/firestore";
import {
    LocalityType,
    LocationDeviceType,
    NeighborhoodType,
    TypeAlertType,
    TypeDeviceType, TypeSensorType
} from "../../shared/types/config.model";
import {AdminDeviceType} from "../../shared/types/company.model";


@Injectable()
export class AppEffects {
    private neighborhoodsCollection: CollectionReference<NeighborhoodType>
    private localitiesCollection: CollectionReference<LocalityType>
    private alertsTypeCollection: CollectionReference<TypeAlertType>

    private typeLocationsDevicesCollection: CollectionReference<LocationDeviceType>

    private typeDevicesCollection: CollectionReference<TypeDeviceType>
    private typeSensorsCollection: CollectionReference<TypeSensorType>

    private adminDevicesCollection: CollectionReference<AdminDeviceType>

    constructor(private actions$: Actions,
                private companyService: CompanyService,
                private _toastService: ToastrService,
                private readonly firestore: Firestore,

    ) {
       this.neighborhoodsCollection = collection(firestore, 'neighborhoods').withConverter<NeighborhoodType>({
            fromFirestore: (snapshot) => {
                const { name, locality_id, city } = snapshot.data();
                const { id } = snapshot;
                return { id, name, locality_id, city };
            },
            toFirestore: (it: NeighborhoodType) => it,
        });

       this.localitiesCollection = collection(firestore, 'localities').withConverter<LocalityType>({
            fromFirestore: (snapshot) => {
                const {name, city, state, state_id} = snapshot.data();
                const {id} = snapshot;
                return {id, name, city, state, state_id};
            },
            toFirestore: (it: LocalityType) => it,
       });

        this.alertsTypeCollection = collection(firestore, 'alert_types').withConverter<TypeAlertType>({
            fromFirestore: (snapshot) => {
                const {name, image, image_dark, others, description, siren, principal, topic, send_media, hidden_app} = snapshot.data() as TypeAlertType;
                let send_media_data = send_media == null ? false : send_media;
                let hidden_app_data = hidden_app ?? false;
                const {id} = snapshot;
                return {id, name, image, image_dark, others, description, siren, principal, topic, send_media, hidden_app: hidden_app_data};
            },
            toFirestore: (it: TypeAlertType) => it,
        });


        this.typeLocationsDevicesCollection = collection(firestore, 'locations-devices').withConverter<LocationDeviceType>({
            fromFirestore: (snapshot) => {
                const {name, status, description} = snapshot.data() as LocationDeviceType;
                const {id} = snapshot;
                return {id, name, status, description};
            },
            toFirestore: (it: LocationDeviceType) => it,
        });

        this.typeDevicesCollection = collection(firestore, 'type-devices').withConverter<TypeDeviceType>({
            fromFirestore: (snapshot) => {
                const {name, description, type, type_sensors, image, type_number} = snapshot.data() as TypeDeviceType;
                const {id} = snapshot;
                return {id, name, description, type, type_sensors, image, type_number};
            },
            toFirestore: (it: TypeDeviceType) => it,
        });

        this.typeSensorsCollection = collection(firestore, 'type_sensor').withConverter<TypeSensorType>({
            fromFirestore: (snapshot) => {
                const {title, description, prefix, suffix, key_value} = snapshot.data() as TypeSensorType;
                const {id} = snapshot;
                return {id, title, description, prefix, suffix, key_value};
            },
            toFirestore: (it: TypeSensorType) => it,
        });

        this.adminDevicesCollection = collection(firestore, 'admin-devices').withConverter<AdminDeviceType>({
            fromFirestore: (snapshot) => {
                const {mac, default_sensor, type_device_id, type, created_at} = snapshot.data() as AdminDeviceType;
                let created = new Date();


                if (created_at instanceof Timestamp) {
                    created =  created_at?.toDate();
                }
                const {id} = snapshot;
                return {id, mac, default_sensor, type_device_id, type, created_at: created};
            },
            toFirestore: (it: AdminDeviceType) => it,
        });



    }

    AppAddListNeighborhoods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppAddListNeighborhoods),
            switchMap(action =>
                from(collectionData(this.neighborhoodsCollection)).pipe(
                    map(data => AppAddListNeighborhoodsSuccess({neighborhoods: data})),
                    catchError(err => of(AppAddListNeighborhoodsFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );



    AppAddListLocalities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppAddListLocalities),
            switchMap(action =>
                from(collectionData(this.localitiesCollection)).pipe(
                    map(data => AppAddListLocalitiesSuccess({localities: data})),
                    catchError(err => of(AppAddListLocalitiesFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

    AppAddListTypeAlerts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppAddListTypeAlerts),
            switchMap(action =>
                from(collectionData(this.alertsTypeCollection)).pipe(
                    map(data => AppAddListTypeAlertsSuccess({type_alerts: data})),
                    catchError(err => of(AppAddListTypeAlertsFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );


    AppAddListTypeLocationsDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppAddListTypeLocationsDevices),
            switchMap(action =>
                from(collectionData(this.typeLocationsDevicesCollection)).pipe(
                    map(data => AppAddListTypeLocationsDevicesSuccess({type_locations_device: data})),
                    catchError(err => of(AppAddListTypeLocationsDevicesFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );



    AppAddListTypeDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppAddListTypeDevices),
            switchMap(action =>
                from(collectionData(this.typeDevicesCollection)).pipe(
                    map(data => AppAddListTypeDevicesSuccess({type_devices: data})),
                    catchError(err => of(AppAddListTypeDevicesFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );


    AppAddListTypeSensors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppAddListTypeSensors),
            switchMap(action =>
                from(collectionData(this.typeSensorsCollection)).pipe(
                    map(data => AppAddListTypeSensorsSuccess({type_sensors: data})),
                    catchError(err => of(AppAddListTypeAlertsFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );


    AppAddListDevicesAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppAddListDevicesAdmin),
            switchMap(action =>
                from(collectionData(this.adminDevicesCollection)).pipe(
                    map(data => AppAddListDevicesAdminSuccess({devices: data})),
                    catchError(err => of(AppAddListDevicesAdminFailure({ error: err })))
                )
            )
        ), { dispatch: true }
    );

}
