export const locale = {
    lang: 'es',
    data: {
        MENU: {
            HOME: 'Inicio',
            DASHBOARDS: {
                ANALYTICS: 'Analítica',
                SENSORS: 'Sensores',
                ALERTS: 'Alertas'
            },
            SAMPLE: 'Ejemplo',
            CHECK: 'Pruebas',
            COMPANY:{
                ALL: 'Empresas'
            },
            ACTION_BUTTON: "Botón de acción",
            CONFIG:{
                LOCALITY: 'Localidades',
                NEIGHBORHOOD: 'Barrios',
                TYPE_ALERT: 'Tipos de alertas',
                TYPE_SENSOR: 'Tipos de sensores',
                TYPE_DEVICE: 'Tipos de dispositivos',
                DEVICES: 'Dispositivos'
            }
        }
    }
}
