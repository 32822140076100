import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import * as AppState from "../../../../store";
import {companyUser} from "../../../../store/selectors/company.selectors";
import {Observable} from "rxjs";
import {CompanyType} from "../../../../shared/types/company.model";

@Component({
  selector: 'app-navbar-company',
  templateUrl: './navbar-company.component.html',
  styleUrls: ['./navbar-company.component.scss']
})
export class NavbarCompanyComponent implements OnInit {

  company$: Observable<CompanyType | null>;

  constructor(
      private store: Store<AppState.State>,
  ) {
    this.company$ = this.store.select(companyUser);

    this.company$.subscribe(value => {
        console.log(value);
    })

  }

  ngOnInit(): void {
  }

}
