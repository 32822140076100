import { Injectable } from '@angular/core';
import {
    collection,
    CollectionReference,
    doc,
    Firestore,
    getDoc,
    query,
    where,
    onSnapshot,
    docSnapshots,
    collectionData, Timestamp, docData, getDocs
} from "@angular/fire/firestore";
import {ActionButtonType, AdminDeviceType, CompanyType, DeviceType, FrontSecurityType, HeadquartersType} from "../types/company.model";

import {typeRole, UserType} from "../types/users.models";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

    private readonly companiesCollectionRef: CollectionReference<CompanyType>
    private readonly headquartersCollectionRef: CollectionReference<HeadquartersType>
    private readonly frontSecuritiesCollectionRef: CollectionReference<FrontSecurityType>
    private readonly devicesCollectionRef: CollectionReference<DeviceType>
    private readonly usersCollectionRef: CollectionReference<UserType>
    private readonly actionButtonsCollectionRef: CollectionReference<ActionButtonType>



  constructor(private readonly firestore: Firestore) {
      this.companiesCollectionRef = collection(this.firestore, 'companies').withConverter<CompanyType>({
          fromFirestore: (snapshot) => {
              const { name, number_of_employees, number_of_branches, number_of_users, nit, address, contact,
                  state, country, state_id, latitude, longitude, zip_code, city, logo, url_devices} = snapshot.data() as CompanyType;
              const { id } = snapshot;
              return { id, name, number_of_employees, number_of_branches, number_of_users, nit, address, contact,
                  state, country, state_id, latitude, longitude, zip_code, city, logo, url_devices};
          },
          toFirestore: (it: CompanyType) => it,
      });

      this.headquartersCollectionRef = collection(firestore, 'headquarters').withConverter<HeadquartersType>({
          fromFirestore: (snapshot) => {
              const { name, address, state, latitude, longitude, zone, city, company_id, state_id, types_alerts, admin_id, alerts_company} = snapshot.data() as HeadquartersType;
              const { id } = snapshot;
              return { id, name, address, state, latitude, longitude, zone, city, company_id, types_alerts, state_id, admin_id, alerts_company};
          },
          toFirestore: (it: HeadquartersType) => it,
      });


      this.frontSecuritiesCollectionRef = collection(firestore, 'front-securities').withConverter<FrontSecurityType>({
          fromFirestore: (snapshot) => {
              const { name,  address, latitude, longitude, company_id, is_private, active, radius, neighborhood, sede_id, hash} = snapshot.data() as FrontSecurityType;
              const { id } = snapshot;
              return { id, name, address, latitude, longitude, company_id, is_private, active, radius, neighborhood, sede_id, hash};
          },
          toFirestore: (it: FrontSecurityType) => it,
      });

      this.devicesCollectionRef = collection(firestore, 'devices').withConverter<DeviceType>({
          fromFirestore: (snapshot) => {
              const { name, company_id, active, front_security_id, type, type_device_id, location_id, created_at,
                  type_camera, mac, ip, dev_index, channels, url_camera, location, hash, hidden, default_sensor, client_id} = snapshot.data() as DeviceType;


              const created = created_at != null ?  (created_at as Timestamp ).toDate(): new Date();

              const { id } = snapshot;
              return {
                  id,
                  name,
                  company_id,
                  active,
                  front_security_id,
                  type,
                  type_device_id,
                  location_id,
                  created_at: created,
                  type_camera,
                  url_camera,
                  mac,
                  ip,
                  dev_index,
                  channels,
                  location,
                  hash,
                  hidden: hidden || false,
                  default_sensor,
                  client_id
              };
          },
          toFirestore: (it: DeviceType) => it,
      });


      this.usersCollectionRef = collection(firestore, 'users').withConverter<UserType>({
          fromFirestore: (snapshot) => {
              const {
                  name, role, company, city, state_id, neighborhood, sede_id, active, dpto, urbanizacion, document,
                  locality, email, verified, document_type, phone, front_security_id, created_at, mac  } = snapshot.data() as UserType;

              const created = created_at != null ?  (created_at as Timestamp ).toDate(): new Date();


              const { id } = snapshot;
              return { id , name, role, company, city, state_id, neighborhood, sede_id, active, dpto, urbanizacion, document,
                  locality, email, verified, document_type, phone,  created_at: created, front_security_id, mac};
          },
          toFirestore: (it: UserType) => it,
      });

    this.actionButtonsCollectionRef = collection(firestore, 'action-buttons').withConverter<ActionButtonType>({
        fromFirestore: (snapshot) => {
            const { name, description, image, company_id, image_dark, type, action} = snapshot.data() as ActionButtonType;

            

            const { id } = snapshot;
            return { id, name, description, image, company_id, image_dark, type, action};
        },
        toFirestore: (it: ActionButtonType) => it,
    });

  }


    getCompanies(user: UserType) {
        if(user.role === typeRole.super_admin) {
            return collectionData(this.companiesCollectionRef, {idField: 'id'});
        }else {
            console.log('user', user);
            console.log('user', user.company);
            return collectionData(query(this.companiesCollectionRef, where('company_id', '==', user.company)), {idField: 'id'});
        }
    }

    getHeadquarters(user: UserType) {
        if(user.role === typeRole.super_admin) {
            return collectionData(this.headquartersCollectionRef, {idField: 'id'});
        }else {
            return collectionData(query(this.headquartersCollectionRef, where('company_id', '==', user.company)), {idField: 'id'});
        }
    }

    getFrontSecurities(user: UserType) {
        if(user.role === typeRole.super_admin) {
            return collectionData(this.frontSecuritiesCollectionRef, {idField: 'id'});
        }else {
            return collectionData(query(this.frontSecuritiesCollectionRef, where('company_id', '==', user.company)), {idField: 'id'});
        }
    }

    getDevices(user: UserType) {
        if(user.role === typeRole.super_admin) {
            return collectionData(this.devicesCollectionRef, {idField: 'id'});
        }else {
            return collectionData(query(this.devicesCollectionRef, where('company_id', '==', user.company)), {idField: 'id'});
        }
    }
    getUsers(user: UserType) {
        if(user.role === typeRole.super_admin) {
            return collectionData(this.usersCollectionRef, {idField: 'id'});
        } else {
            return collectionData(query(this.usersCollectionRef, where('company', '==', user.company)), {idField: 'id'});
        }
    }

    getActionButtons(user: UserType) {
        if(user.role === typeRole.super_admin) {
            return collectionData(this.actionButtonsCollectionRef, {idField: 'id'});
        } else {
            return collectionData(query(this.actionButtonsCollectionRef, where('company_id', '==', user.company)), {idField: 'id'});
        }
    }



    getCompany(id: string) {
        return docData(doc(this.companiesCollectionRef, id), {idField: 'id'});
    }

    getCompanyByUrl(url: string) {
        return collectionData(query(this.companiesCollectionRef, where('url_devices', '==', url)), {idField: 'id'});
    }

    getDevicesByCompany(id: string) {
        return collectionData(query(this.devicesCollectionRef,
            where('company_id', '==', id),
            where('type', '==', 'sensor')),
            {idField: 'id'});

    }


    async validateMacDevice(mac: string) {
         const res = await getDocs(query(this.devicesCollectionRef,
            where('mac', '==', mac),).withConverter({
            fromFirestore: (snapshot) => {
                const { name, company_id, active, front_security_id, type, type_device_id, location_id, created_at,
                    type_camera, mac, ip, dev_index, channels, url_camera, location, hash, hidden, default_sensor} = snapshot.data() as DeviceType;

                const created = created_at != null ?  (created_at as Timestamp ).toDate(): new Date();

                const { id } = snapshot;
                return {
                    id,
                    name,
                    company_id,
                    active,
                    front_security_id,
                    type,
                    type_device_id,
                    location_id,
                    created_at: created,
                    type_camera,
                    url_camera,
                    mac,
                    ip,
                    dev_index,
                    channels,
                    location,
                    hash,
                    hidden: hidden || false,
                    default_sensor
                };

            },
            toFirestore: (it: DeviceType) => it,
             }),
            );

         if(res.docs.length > 0) {
             throw new Error('Este dispositivo ya esta registrado');
         }else {
             const docs = await getDocs(query(collection(this.firestore, 'admin-devices'), where('mac', '==', mac)));

                if(docs.docs.length > 0) {
                    const data = docs.docs[0].data();
                    const { mac, type_device_id, type, default_sensor, created_at } = data as AdminDeviceType;

                    const created = created_at != null ?  (created_at as Timestamp ).toDate(): new Date();

                    const device: AdminDeviceType = {
                        mac,
                        type_device_id,
                        type,
                        default_sensor,
                        created_at: created,
                        id: docs.docs[0].id

                    }


                    if(device) {
                        return device;
                    }
                }else {
                    throw new Error('Este dispositivo no esta en nuestra base de datos');
                }

         }


    }
}
