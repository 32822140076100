import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthenticationService} from "../../../auth/service";
import {Store} from "@ngrx/store";
import * as AppState  from "../../../store"
import {selectAuthIsLoggedIn} from "../../../store/selectors/auth.selectors";
import {map, mergeMap, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
      private _router: Router,
      private _authenticationService: AuthenticationService,
        private store: Store<AppState.State>
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // const user = this._authenticationService.currentUserValue;
      //   console.log(user);
      //   let dateNowSec = new Date().getTime() / 1000;
      //
      //   if (user && user.expires_in > dateNowSec) {
      //       return true;
      //   } else {
      //       this._router.navigate(['/auth/login'],{ queryParams: { returnUrl: state.url } });
      //       return false;
      //   }


      return this.checkStoreAuthentication().pipe(
          mergeMap(storeAuth => {
              console.log('storeAuth', storeAuth);

              if (storeAuth) {
                  return of(true);
              }

              return this.checkApiAuthentication();
          }),
          map(storeOrApiAuth => {
              console.log('storeOrApiAuth', storeOrApiAuth);
              if (!storeOrApiAuth) {
                  this._router.navigate(['/auth/login'],{ queryParams: { returnUrl: state.url } });
                  return false;
              }

              return true;
          })
      );
  }

    checkStoreAuthentication() {
        return this.store.select(selectAuthIsLoggedIn).pipe(take(1));
    }

    checkApiAuthentication() {
        return of(this._authenticationService.currentUserValue);
    }
  
}
